import {mapActions, mapGetters} from 'vuex';

const CONTACT_EMAIL = 'verified_cars@auto.ria.com';
const CATEGORY = 1;

export default {
	name: 'CabinetAutoCheck',
	data(){
		return {
		  contactEmail: CONTACT_EMAIL
		};
	},
	async mounted() {
		const cookies = { Cookie: document.cookie };
		await Promise.allSettled([
			this.fetchManager(),
			this.fetchAutotestPrices({ cookies }),
			this.fetchBrandsByCategory(CATEGORY),
			this.fetchZones()
		]);
	},
	components: {
		'select-auto-block': require('../../Autotest/SelectAuto/index.vue').default,
		'report-main-block': () => import('./MainBlock/index.vue')
	},
	computed: {
		...mapGetters({
			manager_: 'others/autotest/manager',
		}),
	},
	methods: {
		...mapActions({
			fetchManager: 'others/autotest/manager',
			fetchAutotestPrices: 'others/autotest/autotestVinCheckPrices',
			fetchBrandsByCategory: 'shared/brands/fetchByCategory',
			fetchZones: 'shared/locations/fetchZones'
		}),
	},
	i18n: {
		messages: {
			ru: {
				'Результаты проверок авто': 'Результаты проверок авто',
				'Перевірені авто — гарантия того, что у автомобиля нет «темного прошлого», а его цена отвечает качеству.': 'Перевірені авто — гарантия того, что у автомобиля нет «темного прошлого», а его цена отвечает качеству.',
				'проверяет': 'проверяет',
				'техническое состояние': 'техническое состояние',
				'авто и его': 'авто и его',
				'юридическую «чистоту»': 'юридическую «чистоту»',
				'на историю пробега, аресты, угоны, кредиты, и т.д.': 'на историю пробега, аресты, угоны, кредиты, и т.д.',
				'Остались вопросы? Готов проконсультировать': 'Остались вопросы? Готов проконсультировать',
				'менеджер проекта Проверенных авто': 'менеджер проекта Проверенных авто',
				'': '',
			},
			uk: {
				'Результаты проверок авто': 'Результати перевірок авто',
				'Перевірені авто — гарантия того, что у автомобиля нет «темного прошлого», а его цена отвечает качеству.': 'Перевірені авто — гарантія того, що автомобіль не має «темного минулого», а його ціна відповідає якості.',
				'проверяет': 'перевіряє',
				'техническое состояние': 'технічний стан',
				'авто и его': 'авто та його',
				'юридическую «чистоту»': 'юридичну «чистоту»',
				'на историю пробега, аресты, угоны, кредиты, и т.д.': 'на історію пробігу, арешти, викрадення, кредити, тощо',
				'Остались вопросы? Готов проконсультировать': 'Залишилися питання? Готовий проконсультувати',
				'менеджер проекта Проверенных авто': 'менеджер проекту Перевірених авто',
				'': '',
			}
		}
	},
};
