import {mapActions, mapGetters} from 'vuex';
import openBillingWindow from '../../../../../helpers/openBillingWindow';

export default {
  props: ['data'],
  data() {
    return {
      isAddedReport: false,
    };
  },
  computed: {
    ...mapGetters({
      types_: 'cabinet/autotest/type',
      status_: 'cabinet/autotest/status',
      userData: 'Common/userData',
      isWebView: 'shared/device/isWebView',
    }),
    title() {
      return `${this.data?.brand ? this.data?.brand : ''} ${this.data?.model ? this.data?.model : ''} ${this.data?.year ? this.data?.year : ''}`;
    },
    showAddReportBtn() {
      // eslint-disable-next-line max-len
      return !this.data.showOnSite && this.data.statusId === 6 && Number(this.userData.userId) === Number(this.data.ownerId) && !this.isAddedReport;
    },
    flag() {
      let obj = {
        text: this.status_[this.data.statusId],
      };
      switch (true) {
        case [8, 7].includes(this.data.statusId):
          obj.class = 'red';
          break;
        case [0, 1, 2].includes(this.data.statusId):
          obj.class = 'blue';
          break;
        case [3, 4, 5].includes(this.data.statusId):
          obj.class = 'orange';
          break;
          // eslint-disable-next-line max-len
        case !this.data.showOnSite && [6].includes(this.data.statusId) && !this.isAddedReport:
          obj.class = 'green';
          break;
        case this.data.showOnSite && [6].includes(this.data.statusId) || this.isAddedReport:
          obj.class = 'blue-dark';
          // eslint-disable-next-line no-unused-expressions
          this.data.typeId ? obj.text = `${this.$t('Перевірений')} VIN-код` : obj.text = `${this.$t('Перевірений')} ${this.$t('технічно')}`;
          break;
      }
      return obj;
    },
  },
  methods: {
    ...mapActions({
      fetchSaveAsShow: 'cabinet/autotest/saveAsShow',
      fetchAutotestDataVIN: 'cabinet/autotest/getAutotestDataVIN',
    }),
    openBilling(billingId) {
      let billingWindowConfigs = `width=1000,height=780,left=${(screen.width / 2) - 980 / 2},top=80`;
      openBillingWindow(billingId, '', billingWindowConfigs);
    },
    addReport() {
      const userId = this.userData?.userId;
      const {ownerId, orderId, addDate} = this.data || {};
      const isOwner = ownerId === userId;
      const timestamp = new Date(addDate).getTime();
      const hash = Buffer.from(`${timestamp + (ownerId * 10)}`).toString('base64');

      if (isOwner) {
        this.fetchAutotestDataVIN({orderId, hash})
        .then(({autoData: {infotechReportExist = true} = {}}) => {
          const params = {
            orderId,
            status: 1,
            userId,
            type: 'vin',
            infotechReportExist
          };
          this.fetchSaveAsShow(params).then(() => {
            this.isAddedReport = true;
          });
        }).catch(error => console.error('ERROR:::this.fetchAutotestDataVIN', error.message));
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        'Оплатить проверку': 'Оплатить проверку',
        'Смотреть отчет': 'Смотреть отчет',
        'тыс. км': 'тыс. км',
        'от': 'от',
        'Авто з іншого сайту': 'Авто с другого сайта',
        'Перевірений': 'Проверенный',
        'технічно': 'технически',
        'Додати звіт': 'Добавить отчет',
      },
      uk: {
        'Оплатить проверку': 'Оплатити перевірку',
        'Смотреть отчет': 'Дивитися звіт',
        'тыс. км': 'тис. км',
        'от': 'від',
        'Авто з іншого сайту': 'Авто з іншого сайту',
        'Перевірений': 'Перевірений',
        'технічно': 'технічно',
        'Додати звіт': 'Додати звіт',
      }
    }
  }
};
