import {mapActions, mapGetters} from 'vuex';
const EXPIRES = 2592000;
const {get, set} = require('../../../../helpers/cookie');

export default {
	data() {
		return {
			about: undefined,
			status: 0,
			orders: [],
			page: 1,
			limit: _TARGET_ === 'client' ? (Number(get('cabinet_ipp_autocheck')) || 10) : 10,
			count: undefined
		};
	},
	components: {
		'one-order': () => import('./OneOrder/index.vue'),
		'pagination': () => import('../../../Common/Pagination/index.vue'),
	},
	mounted() {
		this.fetchAbout();
		this.renderOrders();
	},
	methods: {
		...mapActions({
			fetchAbout: 'cabinet/autotest/about',
			fetchOrders: 'cabinet/autotest/orderSearch'
		}),
		renderOrders() {
			this.fetchOrders({status: this.status, limit: this.limit, offset: this.page * this.limit - this.limit}).then((response) => {
				let {count, result} = response;
				if (result) {
					this.orders = result;
				}
				this.count = count;
			});
		},
		onChangeLimit(limit) {
			this.page = 1;
			this.limit = limit;
			set('cabinet_ipp_autocheck', limit, { expires: EXPIRES });
			this.renderOrders();
		},
		onChangePage(page) {
			this.page = page;
			this.renderOrders();
		},
	},
	computed: {
		...mapGetters({
			statusFilter_: 'cabinet/autotest/statusFilter',
		}),
		pages() {
			return Math.ceil(this.count / this.limit);
		},
	},
	watch: {
		status() {
			this.renderOrders();
		}
	},
	i18n: {
		messages: {
			ru: {
				'Сортировать по статусу': 'Сортировать по статусу',
				'На жаль, по Вашому запиту перевірок авто не знайдено': 'К сожалению, по Вашему запросу проверок авто не найдено',
				'Дізнайтесь більше про всі': 'Узнайте больше о всех',
				'перевірки в подробицях': 'проверках в подробностях',
				'Яку інформацію про авто та з яких джерел ви отримаєте на AUTO.RIA?': 'Какую информацию об авто и из каких источников вы получите на AUTO.RIA?',
			},
			uk: {
				'Сортировать по статусу': 'Сортувати по статусу',
				'На жаль, по Вашому запиту перевірок авто не знайдено': 'На жаль, по Вашому запиту перевірок авто не знайдено',
				'Дізнайтесь більше про всі': 'Дізнайтесь більше про всі',
				'перевірки в подробицях': 'перевірки в подробицях',
				'Яку інформацію про авто та з яких джерел ви отримаєте на AUTO.RIA?': 'Яку інформацію про авто та з яких джерел ви отримаєте на AUTO.RIA?',
			}
		}
	}
};
